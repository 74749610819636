export const ITEM_CATEGORIES = {
  'Camera Equipment': {
    Cameras: {
      Type: [
        'Digital',
        'DSLR',
        'HD',
        'Mirrorless',
        'Action Cams',
        'Point and Shoot',
        'Medium Format',
      ],
    },
    Lenses: {
      Type: ['Cinema', 'DSLR', 'Mirrorless', 'Accessories'],
    },
    Lighting: {
      Type: ['Continuous', 'Strobe', 'Flash', 'Accessories'],
    },
    'Audio Equipment': {
      Type: ['Mic', 'Audio Recorders', 'Mixers', 'Accessories'],
    },
    'Tripods and Rigs': {
      Type: [
        'Fluid Head',
        'Pan & Tilt',
        'Monopods',
        'Sliders & Dollies',
        'Jibs & Cranes',
        'Gimbals',
        'Steadicams',
        'Stabilizers',
      ],
    },
    Accessories: {
      Type: ['Filters', 'Power Cords', 'I/O Attachments'],
    },
    'Studio Spaces': {},
  },
  'Audio Equipment': {
    Speakers: {
      Type: ['Systems', 'Portable', 'Bass'],
    },
    Microphones: {
      Type: ['Standard', 'Recording', 'Wireless', 'USB'],
    },
    'DJ Equipment': {
      Type: ['Turntables', 'Mixers', 'Controllers'],
    },
  },
  Drones: {
    Drones: {
      Brand: ['DJI', 'GoPro', 'Parrot', 'Blade', 'Other'],
    },
    Accessories: {},
  },
  Mobility: {
    Bikes: {
      Type: ['Racing', 'Mountain', 'Road', 'BMX', 'Cruiser', 'Track', 'Other'],
      Electric: ['Pedal Assist', 'Throttle', 'None'],
      'Wheel Size': [],
    },
    'Electric Scooters': {},
    'Electric Skateboards': {},
    Other: {},
  },
  'Party & Events': {
    'Catering Equipment': {},
    Decorations: {},
    Furniture: {},
    'Canopy Tent': {},
    Lighting: {
      Type: ['Stage Lights', 'String Lights', 'Strobe Lights'],
    },
    Other: {},
  },
  Home: {
    Cooking: {},
    Storage: {},
    Accessories: {},
  },
  Entertainment: {
    'Video Games': {
      Type: ['Console', 'Games', 'Accessories'],
    },
    Devices: {},
    TVs: {},
    Projectors: {
      Type: ['Standard', 'Short Throw', 'Screens'],
    },
    VR: {},
    Accessories: {},
  },
  'Musical Instruments': {
    Guitars: {
      Type: ['Acoustic', 'Bass', 'Electric', 'Pedals', 'Accessories'],
    },
    Amps: {
      Type: ['Guitar', 'Bass', 'Piano', 'Other'],
    },
    Keyboards: {},
    'String Instruments': {},
    Brass: {},
    Woodwind: {},
    Percussion: {},
    Accessories: {},
  },
  Outdoor: {
    'Camp & Hiking': {
      Type: [
        'Tents',
        'Pads & Sleeping Bags',
        'Air Mattresses',
        'Cots',
        'Hammocks',
        'Cooking Equipment',
        'Gadgets & Gear',
        'Electronics',
      ],
    },
    'Fishing Gear': {},
    'Climbing Gear': {
      Type: ['Hardware', 'Ropes', 'Pads', 'General'],
    },
    Sports: {
      Type: ['Gym Equipment', 'Water', 'Winter', 'Yoga', 'Recovery', 'General'],
    },
    Other: {},
  },
  Miscellaneous: {},
}
