import React from 'react'
import { Link } from 'gatsby'

import CongratulationTextFormElement from '../elements/CongratulationTextFormElement'
import CheckStripeFormElement from '../elements/CheckStripeFormElement'
import ItemDisplay from 'components/shared/Item/ItemDisplay'

export default class ConfirmationStep extends React.Component {
  render() {
    let { data } = this.props
    const { visible, user } = this.props
    return (
      <div style={{ display: !visible && 'none' }}>
        <ItemDisplay data={data} status="waiting approval" showStatus={true} />
        <CongratulationTextFormElement />
        <CheckStripeFormElement user={user} />
      </div>
    )
  }
}
