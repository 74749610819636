import React, { Component } from 'react'
import styled from '@emotion/styled'
import { css, keyframes } from '@emotion/core'

import { colors } from 'utils/styles'

const BounceAnimation = keyframes`
 from, 20%, 53%, 80%, to {
    transform: translate3d(0,0,0);
  }

  40%, 43% {
    transform: translate3d(0, -20px, 0);
  }

  70% {
    transform: translate3d(0, -8px, 0);
  }

  90% {
    transform: translate3d(0,-3px,0);
  }
`

const DotWrapper = styled.div`
  display: flex;
  align-items: flex-end;
`

const Dot = styled.div`
  background-color: ${colors.brandBright};
  border-radius: 50%;
  width: 10px;
  height: 10px;
  margin: 0 5px;
  /* Animation */
  animation: ${BounceAnimation} 1s ease infinite;
  animation-delay: ${props => props.delay};
`

class LoadingDots extends Component {
  render() {
    let { loading } = this.props
    return (
      <DotWrapper>
        {loading ? (
          <>
            <Dot delay="0s" />
            <Dot delay=".1s" />
            <Dot delay=".2s" />
          </>
        ) : (
          <></>
        )}
      </DotWrapper>
    )
  }
}
export default LoadingDots
