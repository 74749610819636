import React from 'react'
import Geocode from 'react-geocode'
import { navigate } from 'gatsby'

import './style.scss'
import LoadingDots from 'components/shared/Forms/LoadingDots'
import ProgressFormElement from './elements/ProgressFormElement'
import FirstStep from './steps/FirstStep'
import SecondStep from './steps/SecondStep'
import ThirdStep from './steps/ThirdStep'
import ConfirmationStep from './steps/ConfirmationStep'

const slug = word => {
  if (!word) return
  return word
    .toLowerCase()
    .replace(' ', '-')
    .replace('& ', '')
}

export default class SubmitForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      currentStep: 1,
      item_name: '',
      item_pictures: [],
      item_type: '',
      item_subtype: '',
      item_price: 0,
      item_description: '',
      item_address: '',
      item_zip: '',
      item_city: '',
      item_state: '',
      item_lat: '',
      item_lng: '',
    }
  }

  componentDidMount() {
    Geocode.setApiKey('AIzaSyA_0G3ZKX89wmcQdG84SbwYrh0bMwotH7U')
  }

  _getAddress = () => {
    this._moveForward()

    let city,
      state,
      lat,
      lng = ''
    Geocode.fromAddress(this.state.item_zip).then(
      response => {
        // find city and state
        if (response.results[0]) {
          response.results[0].address_components.forEach(entry => {
            if (entry.types[0] == 'locality') {
              city = entry.long_name
            } else if (entry.types[0] == 'administrative_area_level_1') {
              state = entry.short_name
            }
          })
          // Get lat/lng based on zip and add a little noise
          lat =
            response.results[0].geometry.location.lat +
            (Math.random() * (0.05 - 0.01) + 0.01)
          lng =
            response.results[0].geometry.location.lng +
            (Math.random() * (0.05 - 0.01) + 0.01)
        }
        this.setState(
          {
            item_city: city,
            item_state: state,
            item_lat: lat,
            item_lng: lng,
          },
          () => {
            this._submit()
          }
        )
      },
      error => {
        console.error(error)
        // submit even if we can't get city and state
        this._submit()
      }
    )
  }

  _moveForward = () => {
    if (!this.form.reportValidity()) {
      return
    }
    this.setState({ currentStep: this.state.currentStep + 1 })
  }

  _moveBackward = () => {
    this.setState({ currentStep: this.state.currentStep - 1 })
  }

  _submit = () => {
    this.setState({ loading: true })
    let type_slug = slug(this.state.item_type)
    let subtype_slug = slug(this.state.item_subtype)
    let item = {
      name: this.state.item_name,
      pictures: this.state.item_pictures,
      type: type_slug,
      subtype: subtype_slug,
      price_d: this.state.item_price,
      description: this.state.item_description,
      street_address: this.state.item_address,
      zip: this.state.item_zip,
      city: this.state.item_city,
      state: this.state.item_state,
      lat: this.state.item_lat,
      lng: this.state.item_lng,
    }
    console.log(item)
    ;(async () => {
      const error = await this.props.onSubmit(item)
      if (error) {
        this.setState({ error, loading: false })
        console.log(error)
      }
      this.setState({ success: true, loading: false })
    })()
  }

  refreshForm = () => {
    navigate('/submit')
  }

  onChange = event => {
    if (event.target.value == null) return
    this.setState({ [event.target.name]: event.target.value })
  }

  onChangePrice = event => {
    this.setState({ item_price: event.target.value * 100 })
  }

  uploadImg = info => {
    let splitUrl = info.cdnUrl.split('~')
    let imgCount = parseInt(splitUrl[splitUrl.length - 1].replace('/', ''))
    let pictures = []
    for (var i = 0; i < imgCount; i++) {
      let imgUrl = info.cdnUrl + 'nth/' + i + '/'
      pictures.push(imgUrl)
    }
    this.setState(prevState => ({
      item_pictures: pictures,
    }))
  }

  renderStep() {
    const { currentStep, success } = this.state
    return (
      <form ref={el => (this.form = el)}>
        {currentStep == 1 ? (
          <FirstStep
            visible={currentStep == 1}
            initialData={{ ...this.state }}
            onChange={this.onChange}
            onChangePrice={this.onChangePrice}
            forward={this._moveForward}
          />
        ) : currentStep == 2 ? (
          <SecondStep
            visible={currentStep == 2}
            initialData={{ ...this.state }}
            uploadImg={this.uploadImg}
            onChange={this.onChange}
            back={this._moveBackward}
            forward={this._moveForward}
          />
        ) : currentStep == 3 ? (
          <ThirdStep
            visible={currentStep == 3}
            initialData={{ ...this.state }}
            onChange={this.onChange}
            back={this._moveBackward}
            submit={this._getAddress}
          />
        ) : (
          <ConfirmationStep
            user={this.props.user}
            visible={success}
            data={{ ...this.state }}
            refreshForm={this.refreshForm}
          />
        )}
        <h2 style={{ display: currentStep != 4 && 'none' }}>
          {this.state.error && 'Processing Error'}
        </h2>
        {this.state.error && (
          <p className="hint-text">
            We were not able to process your request at this time. Please try
            again later.
          </p>
        )}
        <div className="loading-animation">
          <LoadingDots loading={this.state.loading} />
        </div>
      </form>
    )
  }

  render() {
    let { currentStep } = this.state
    return (
      <section className="submit-page-container">
        <div className="submit-page-subcontainer">
          <ProgressFormElement
            currentStep={currentStep}
            visible={currentStep <= 3}
          />
          {this.renderStep()}
        </div>
      </section>
    )
  }
}
