import React from 'react'
import styled from '@emotion/styled'
import { navigate } from 'gatsby'

import { FormButtonElements } from './ButtonElements'
import StripeWarningFormElement from './StripeWarningFormElement'
import { colors } from 'utils/styles'

export default class CheckStripeFormElement extends React.Component {
  render() {
    let user = this.props.user || {}
    return (
      <>
        {!user.stripe_user_id ? (
          <StripeWarningFormElement />
        ) : (
          <FormButtonElements
            list={() => navigate('/submit')}
            inventory={() => navigate('/dashboard/items')}
          />
        )}
      </>
    )
  }
}
