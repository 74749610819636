import React from 'react'
import styled from '@emotion/styled'

import { colors } from 'utils/styles'

const SuccessBox = styled('div')`
  margin: 20px 0 30px 0;
`

export default class CongratulationTextFormElement extends React.Component {
  render() {
    return (
      <SuccessBox>
        <h4>Item Created Successfully</h4>
        <div>You will recieve a notification when it has been approved.</div>
        <div> This usually takes less than 24 hours.</div>
      </SuccessBox>
    )
  }
}
