import React from 'react'
import styled from '@emotion/styled'
import { navigate } from 'gatsby'

import { StripeConnectButton, FormButtonElements } from './ButtonElements'
import { colors } from 'utils/styles'

const WarningBox = styled('div')`
  border-radius: 4px;
  color: ${colors.brand};
  padding: 15px 17px;
  background-color: ${colors.brandLight};
  font-weight: 600;
  font-size: 11px;
  margin: 30px 0;
`

const WarningHeader = styled('div')`
  display: flex;
  height: 20px;
  align-items: center;
  margin-bottom: 5px;
  opacity: 0.7;
`

const WarningIcon = styled('svg')`
  height: 10px;
`

const WarningTitleContainer = styled('div')`
  margin-left: 10px;
  display: flex;
`

const WarningTitle = styled('h5')`
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
`

const WarningBody = styled('p')`
  font-size: 14px;
  margin-bottom: 0;
`

export default class StripeWarningFormElement extends React.Component {
  render() {
    return (
      <>
        <WarningBox>
          <WarningHeader>
            <WarningIcon
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
              focusable="false"
              data-prefix="fas"
              data-icon="exclamation-triangle"
              role="img"
              viewBox="0 0 576 512"
              data-ember-extension="1"
            >
              <path
                fill="currentColor"
                d="M569.517 440.013C587.975 472.007 564.806 512 527.94 512H48.054c-36.937 0-59.999-40.055-41.577-71.987L246.423 23.985c18.467-32.009 64.72-31.951 83.154 0l239.94 416.028zM288 354c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z"
              />
            </WarningIcon>
            <WarningTitleContainer>
              <WarningTitle>Warning</WarningTitle>
            </WarningTitleContainer>
          </WarningHeader>
          <WarningBody>
            Before your item can be pubically listed, you need to connect a
            Stripe account so that you can be paid.
          </WarningBody>
        </WarningBox>
        <StripeConnectButton onClick={() => navigate('/onboarding')} />
      </>
    )
  }
}
