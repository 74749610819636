import React from 'react'
import styled from '@emotion/styled'

import { colors } from 'utils/styles'

const DotWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 0px 50px 20px 50px;
  margin-top: -5px;
  justify-content: space-between;
  width: 100%;
`

const Dot = styled.div`
  background-color: ${props =>
    props.selected ? colors.brand : colors.brandBright};
  border-radius: 50%;
  width: 10px;
  height: 10px;
  margin: 0 10px;
`

const Step = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`

const StepName = styled.div`
  margin-top: 8px;
  visibility: ${props => (props.selected ? 'visible' : 'hidden')};
  color: ${colors.brand};
  font-weight: 600;
`

export default class ProgressFormElement extends React.Component {
  render() {
    const { currentStep, visible } = this.props
    return (
      <DotWrapper style={{ display: !visible && 'none' }}>
        <Step>
          <Dot selected={currentStep == 1} />
          <StepName selected={currentStep == 1}>1</StepName>
        </Step>
        <Step>
          <Dot selected={currentStep == 2} />
          <StepName selected={currentStep == 2}>2</StepName>
        </Step>
        <Step>
          <Dot selected={currentStep == 3} />
          <StepName selected={currentStep == 3}>3</StepName>
        </Step>
      </DotWrapper>
    )
  }
}
