import React from 'react'
import styled from '@emotion/styled'

import { button } from 'utils/styles'

const InventoryBtn = styled('a')`
  ${button.purple};
  ${button.big};
  flex: 1;
  margin-top: 10px;
  @media screen and (min-width: 400px) {
    margin: 0 0 0 30px;
  }
`

const ListAnotherBtn = styled('a')`
  ${button.default};
  ${button.big};

  @media screen and (max-width: 400px) {
    width: 100%;
  }
`

const StripeConnectBtn = styled('a')`
  ${button.purple};
  ${button.big};
`

const BtnRow = styled('div')`
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  max-width: 422px;
  margin-top: 25px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  flex: 1;
  justify-content: space-between;
`

export const StripeConnectButton = props => {
  if (!props.onClick) {
    return <div />
  }
  return <StripeConnectBtn {...props}>Connect Stripe</StripeConnectBtn>
}

export const ListAnotherButton = props => {
  if (!props.onClick) {
    return <div />
  }
  return <ListAnotherBtn {...props}>List another</ListAnotherBtn>
}

export const InventoryButton = props => {
  if (!props.onClick) {
    return <div />
  }
  return <InventoryBtn {...props}>View your inventory</InventoryBtn>
}

export const FormButtonElements = props => {
  return (
    <BtnRow>
      <ListAnotherButton onClick={props.list} />
      <InventoryButton onClick={props.inventory} />
    </BtnRow>
  )
}
