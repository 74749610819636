import React from 'react'

import { FormButtonsElement } from 'components/shared/Forms/FormCommonButtons'
import Uploader from 'components/shared/Forms/Uploader'

export default class SecondStep extends React.Component {
  render() {
    const { initialData } = this.props
    const { item_pictures, item_description } = initialData

    return (
      <>
        <div style={{ marginBottom: 20 }}>
          <h3 className="sub-title">Add a photo</h3>
          {item_pictures && (
            <div class="item-picture-preview">
              {item_pictures.map(picture => (
                <img src={picture} height="100" width="100" />
              ))}
            </div>
          )}
          <Uploader
            id="file"
            name="item_pictures"
            multiple={true}
            required
            onUploadComplete={this.props.uploadImg}
          />
        </div>
        <div className="submit-item-description-container">
          <h3 className="sub-title">Tell us about your item</h3>
          <div className="flex-column" width="100%">
            <fieldset className="fieldset fieldset-with-label">
              <label htmlFor="item_description">Description</label>
              <textarea
                className="text-input"
                name="item_description"
                type="text"
                required
                maxLength="1600"
                rows="6"
                defaultValue={item_description}
                onChange={this.props.onChange}
              />
            </fieldset>
          </div>
        </div>
        <FormButtonsElement
          {...this.props}
          nextReady={item_pictures.length != 0 && item_description != ''}
        />
      </>
    )
  }
}
