import React from 'react'

import './style.scss'
import '../rent-item/style.scss'

import { default as Layout } from 'layouts/AuthenticatedPage'
import SEO from 'components/SEO/SEO'
import SubmitForm from 'components/Submit'

class SubmitPage extends React.Component {
  state = {
    user: null,
  }

  componentDidMount = async () => {
    const user = await this.props.database.getCurrentUser()
    if (user.exists) {
      const data = user.data()
      this.setState({ user: data })
    }
  }

  onSubmit = async item => {
    try {
      await this.props.database.createItem(item)
    } catch (error) {
      return error
    }
  }

  render() {
    return (
      <div id="submit-page">
        <div className="content-wrap">
          <section className="page-title form-title">
            <h2>List your gear</h2>
          </section>
          <SubmitForm onSubmit={this.onSubmit} user={this.state.user} />
        </div>
      </div>
    )
  }
}

export default ({ location }) => (
  <Layout displayFooter={false} location={location} showBeacon={true}>
    <SEO />
    <SubmitPage />
  </Layout>
)
