import React from 'react'

import { FormButtonsElement } from 'components/shared/Forms/FormCommonButtons'

export default class ThirdStep extends React.Component {
  render() {
    const { initialData, visible } = this.props
    const { item_address, item_zip } = initialData

    return (
      <div style={{ display: !visible && 'none' }}>
        <div className="submit-item-location-container">
          <h3 className="sub-title">Add Location of item</h3>
          <p className="hint-text" style={{ marginBottom: 10 }}>
            We will only share an approximate location with viewers
          </p>
          <fieldset className="fieldset fieldset-with-label">
            <label htmlFor="item_address">Address</label>
            <input
              id="item_address"
              name="item_address"
              type="text"
              required
              defaultValue={item_address}
              onChange={this.props.onChange}
            />
          </fieldset>
          <fieldset className="fieldset fieldset-with-label">
            <label htmlFor="item_zip">Zipcode</label>
            <input
              id="item_zip"
              name="item_zip"
              type="text"
              required
              defaultValue={item_zip}
              onChange={this.props.onChange}
            />
          </fieldset>
        </div>
        <FormButtonsElement
          {...this.props}
          nextReady={item_address != '' && item_zip != ''}
        />
      </div>
    )
  }
}
