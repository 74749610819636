/**
 * Some Button Form Elements
 * If no onClick handler is provided then the button
 * will not be rendered. This is useful shorthand for
 * selectively displaying buttons on certain forms but
 * not others. This could also be used to show a disabled
 * version of the button. Sky's the limit.
 */
import React from 'react'
import styled from '@emotion/styled'

import { button } from 'utils/styles'

const BackBtn = styled('a')`
  ${button.default};
  ${button.big};
`

const ForwardBtn = styled('a')`
  ${button.purple};
  ${button.big};
`

export const BackButton = props => {
  if (!props.onClick) {
    return <div />
  }
  return (
    <div>
      <BackBtn {...props}>Back</BackBtn>
    </div>
  )
}

export const ForwardButton = props => {
  if (!props.onClick) {
    return <div />
  }

  return (
    <div>
      <ForwardBtn {...props} disabled={!props.nextReady}>
        {props.nextText || 'Next'}
      </ForwardBtn>
    </div>
  )
}

export const SubmitButton = props => {
  if (!props.onClick) {
    return <div />
  }
  return (
    <div>
      <ForwardBtn {...props}>Submit</ForwardBtn>
    </div>
  )
}

export const FormButtonsElement = props => {
  return (
    <div className="bottom-btn-container">
      <BackButton onClick={props.back} />
      {props.forward ? (
        <ForwardButton onClick={props.forward} nextReady={props.nextReady} />
      ) : (
        <SubmitButton onClick={props.submit} disabled={props.disabled} />
      )}
    </div>
  )
}
