import React from 'react'
import styled from '@emotion/styled'
import { Link } from 'gatsby'

import { colors } from 'utils/styles'
import StatusTag from 'components/shared/Tags/StatusTag'

export default class ItemDisplay extends React.Component {
  render() {
    const { data, showStatus, status } = this.props
    const {
      item_name,
      item_pictures,
      item_type,
      item_price,
      item_description,
    } = data

    return (
      <div className="rental-display-container">
        <div className="rental-subcontainer">
          <div className="flex-column">
            <div className="rental-row">
              <div className="display-img-container">
                {item_pictures && (
                  <img className="rental-img" src={item_pictures[0]} />
                )}
              </div>
              <div className="rental-title-container">
                <p className="display-title">{item_name}</p>

                <p className="hint-text">Price: ${item_price / 100} /day</p>
              </div>
            </div>
            <div className="display-container-row">
              <p className="normal-txt">{item_description}</p>
            </div>
            {showStatus && (
              <>
                {status && <StatusTag status={status} />}
                <div className="rental-footer">
                  <Link to={`/dashboard/items`}>
                    <p className="view-on-dashboard">
                      View item on your dashboard
                    </p>
                  </Link>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    )
  }
}
