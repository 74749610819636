import React from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/core'

import { FormButtonsElement } from 'components/shared/Forms/FormCommonButtons'
import { input, select, spacing } from 'utils/styles'

import * as categories from 'constants/categories'

const inputStyles = css`
  ${input.default};
  margin-top: ${spacing.sm}px;
  width: 100%;
  :hover {
    ${input.hover};
  }
  :focus {
    ${input.focus};
  }
  max-width: 80%;
`

const CategorySelect = styled('select')`
  ${inputStyles};
  ${select.default};
  margin: 0;
  max-width: 100%;
  padding: 10px 12px;

  @media (min-width: 650px) {
    ${select.small};
  }
`

const slug = word => {
  return word
    .toLowerCase()
    .replace(' ', '-')
    .replace('& ', '')
}

export default class FirstStep extends React.Component {
  render() {
    const { initialData, visible } = this.props
    const { item_name, item_price, item_type, item_subtype } = initialData
    return (
      <div style={{ display: !visible && 'none' }}>
        <h3 className="sub-title">Give it a name and price</h3>
        <div className="submit-item-container-input-container">
          <div className="item-name-price-container">
            <fieldset className="fieldset fieldset-with-label item-name">
              <label htmlFor="item_name">Item name</label>
              <input
                type="text"
                required
                name="item_name"
                id="item_name"
                maxLength="250"
                defaultValue={item_name}
                onChange={this.props.onChange}
              />
            </fieldset>
            <fieldset className="fieldset fieldset-with-label item-price">
              <label htmlFor="item_price">Price (Daily $)</label>
              <input
                name="item_price"
                required
                id="item_price"
                type="number"
                min="0"
                max="10000"
                onChange={this.props.onChangePrice}
              />
            </fieldset>
          </div>
        </div>
        <div className="submit-item-category-container">
          <h3 className="sub-title">Select a category</h3>
          <fieldset className="fieldset fieldset-with-label item-price">
            <label htmlFor="item_type">Category</label>
            <CategorySelect
              name="item_type"
              required
              value={item_type}
              onChange={this.props.onChange}
            >
              <option value="" disabled="">
                {' '}
                Select category
              </option>
              {Object.keys(categories.ITEM_CATEGORIES).map(key => {
                return (
                  <option value={key} key={key}>
                    {key}
                  </option>
                )
              })}
            </CategorySelect>
          </fieldset>
          {item_type &&
            Object.keys(categories.ITEM_CATEGORIES[item_type]).length != 0 && (
              <fieldset className="fieldset fieldset-with-label item-price">
                <label htmlFor="item_subtype">Sub-category</label>
                <CategorySelect
                  name="item_subtype"
                  required
                  value={item_subtype}
                  onChange={this.props.onChange}
                >
                  <option value="" disabled="">
                    {' '}
                    Select category
                  </option>
                  {Object.keys(categories.ITEM_CATEGORIES[item_type]).map(
                    key => {
                      return (
                        <option key={key} value={key}>
                          {key}
                        </option>
                      )
                    }
                  )}
                </CategorySelect>
              </fieldset>
            )}
        </div>

        <FormButtonsElement
          {...this.props}
          nextReady={item_name != '' && item_type != '' && item_price != ''}
        />
      </div>
    )
  }
}
